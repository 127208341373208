// Call the dataTables jQuery plugin
$(document).ready(function () {
    // $('#email_template_list').DataTable();
    // $('table.table-bordered').DataTable({
    //     "language": {
    //         "search": '<button class="btn btn-primary btn-sm ml-2 mb-2 reset" type="button">Reset</button>',
    //         "searchPlaceholder": "Search for...",
    //         "paginate": {
    //             "previous": '<i class="fa fa-angle-left"></i>',
    //             "next": '<i class="fa fa-angle-right"></i>'
    //         }
    //     },
    //     "stripeClasses": ['odd-row', 'even-row']
    // });
    // $('#email_mgmt_50').DataTable({
    //     "language": {
    //             "search": '<button class="btn btn-primary btn-sm ml-3 mb-2 reset" type="button">Reset</button>',
    //             "searchPlaceholder": "search",
    //             "paginate": {
    //             "previous": '<i class="fa fa-angle-left"></i>',
    //                 "next": '<i class="fa fa-angle-right"></i>'
    //         }
    //     },
    //      "stripeClasses": [ 'odd-row', 'even-row' ]
    // });
    // $('#email_mgmt_ISP').DataTable({
    //     "language": {
    //             "search": '<button class="btn btn-primary btn-sm ml-3 mb-2 reset" type="button">Reset</button>',
    //             "searchPlaceholder": "search",
    //             "paginate": {
    //             "previous": '<i class="fa fa-angle-left"></i>',
    //                 "next": '<i class="fa fa-angle-right"></i>'
    //         }
    //     },
    //      "stripeClasses": [ 'odd-row', 'even-row' ]
    // });
    // $('#app_selected_email_mgmt').DataTable({
    //     "language": {
    //             "search": '<button class="btn btn-primary btn-sm ml-3 mb-2 reset" type="button">Reset</button>',
    //             "searchPlaceholder": "search",
    //             "paginate": {
    //             "previous": '<i class="fa fa-angle-left"></i>',
    //                 "next": '<i class="fa fa-angle-right"></i>'
    //         }
    //     },
    //      "stripeClasses": [ 'odd-row', 'even-row' ]
    // });
    // $('#app_decline_email_mgmt').DataTable({
    //     "language": {
    //             "search": '<button class="btn btn-primary btn-sm ml-3 mb-2 reset" type="button">Reset</button>',
    //             "searchPlaceholder": "search",
    //             "paginate": {
    //             "previous": '<i class="fa fa-angle-left"></i>',
    //                 "next": '<i class="fa fa-angle-right"></i>'
    //         }
    //     },
    //      "stripeClasses": [ 'odd-row', 'even-row' ]
    // });

    $('.reset').click(function () {
        $('table.table-bordered').dataTable().fnFilter('');
        //  $('#email_mgmt_50').dataTable().fnFilter('');
        //  $('#email_mgmt_ISP').dataTable().fnFilter('');
        //  $('#app_selected_email_mgmt').dataTable().fnFilter('');
        //  $('#app_decline_email_mgmt').dataTable().fnFilter('');
    });
});